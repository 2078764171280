<template>
  <div>
    <v-container v-if="client">
      <v-card class="table-card">
        <v-card-title>
          <v-row class="d-flex justify-space-between align-center mb-2 px-2">
            <div class="d-flex">
              <v-avatar size="100" class="elevation-6 ms-1" :color="color">
                <v-icon dark size="64">
                  mdi-account-tie
                </v-icon>
              </v-avatar>
              <div class="table-card-title ms-5 text-h2 d-flex">
                <span class="d-none d-sm-block">{{ $t('client') }}&nbsp;-&nbsp;</span>
                <span class="ms-sm-2">{{ client.name }}</span>
              </div>
            </div>
          </v-row>
        </v-card-title>

        <v-card-text>
          <router-view />
        </v-card-text>
      </v-card>

      <v-card>
        <v-tabs
          v-model="tab"
          background-color="primary"
          centered
          dark
          icons-and-text
        >
          <v-tabs-slider />

          <v-tab :to="{ name: 'ClientDetails' }">
            {{ $t('details') }}
            <v-icon size="30">
              mdi-account-details
            </v-icon>
          </v-tab>

          <v-tab :to="{ name: 'SubClients' }">
            {{ $t('clients') }}
            <v-icon size="30">
              mdi-account-multiple
            </v-icon>
          </v-tab>

          <v-tab :to="{ name: 'ClientUsers' }">
            {{ $t('users') }}
            <v-icon size="30">
              mdi-account-group
            </v-icon>
          </v-tab>

          <v-tab :to="{ name: 'ClientDevices' }">
            {{ $t('devices') }}
            <v-icon size="30">
              mdi-router-wireless
            </v-icon>
          </v-tab>
        </v-tabs>
      </v-card>
    </v-container>

    <v-container v-else>
      <v-progress-linear :indeterminate="true" />
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Client',

  data: () => ({
    icon: 'md-account-tie',
    color: 'blue',
    tab: 0,
  }),

  computed: {
    client () {
      return this.$store.getters['clients/client'](this.$route.params.code)
    },
  },

  mounted () {
    // console.log(this.$vuetify.icons.values.clientsGroup.component)
  },
}
</script>
